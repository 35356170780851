<template>
	<c-form ref="form">
		<c-form-item type="hidden" name="bx_id"></c-form-item>
		<c-form-item type="input" label="项目名称" name="project_name" required disabled></c-form-item>
		<c-form-item type="input" label="沟通人" name="gtr_xm" required></c-form-item>
		<c-form-item type="input" label="沟通电话" name="gtr_lxdh" required></c-form-item>
		<c-form-item type="select" label="协助理赔方" name="xzlp_user_id" options="ASSIST_CLAIM" search></c-form-item>
		<c-form-item type="file" label="理赔材料" name="file_path" file-type="word,excel,pdf,zip,image"></c-form-item>
		<c-form-item type="textarea" label="理赔原因" name="remark"></c-form-item>
	</c-form>
</template>

<script>
	export default {
		methods: {
			init(id) {
				this.$refs.form.clear();
				this.request({
					url: '/project/bx/detail',
					data: {
						bx_id: id
					},
					success: data => {
						this.$refs.form.set({
							bx_id: data.bxInfo.id,
							project_name: data.bxInfo.project_name
						});
					}
				});
			},
			
			submit() {
				return new Promise(resolve => {
					this.$refs.form.submit({
						url: '/project/bxlp/add',
						rule: {
							gtr_xm: '请填写沟通人姓名',
							gtr_lxdh: '请填写沟通电话'
						},
						success: resolve
					});
				})
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>
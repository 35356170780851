import { render, staticRenderFns } from "./insurance_list_m.vue?vue&type=template&id=03627bb1&scoped=true"
import script from "./insurance_list_m.vue?vue&type=script&lang=js"
export * from "./insurance_list_m.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03627bb1",
  null
  
)

export default component.exports
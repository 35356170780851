<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="企业名称" name="zbqy_name"></c-form-item>
					<c-form-item type="input" label="项目编号" name=""></c-form-item>
					<c-form-item type="input" label="项目名称" name="name"></c-form-item>
					<c-form-item type="datetime" label="申请时间" start-name="start_date" end-name="end_date"></c-form-item>
					<c-form-item type="select" label="保单类型" name="bx_type_id" options="INSURANCE_TYPE"></c-form-item>
					<c-form-item type="select" label="保单状态" name="state" :options="insuranceStateList"></c-form-item>
					<c-form-item type="select" label="金融机构" name="" options="INSURANCE_COMPANY"></c-form-item>
				</c-form>
			</m-search>
			
			<c-table ref="table">
				<c-table-column
					label="状态"
					width="100"
				>
					<template v-slot="{data}">
						<span v-if="insuranceState[data.state]" :style="{'color': insuranceState[data.state].color}">{{insuranceState[data.state].name}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="企业名称"
					name="zbqy_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="保单类型"
					name="bx_type_name"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="项目名称"
					name="project_name"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="金融机构"
					name="bxgs_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="保单金额"
					width="120"
				>
					<template v-slot="{data}">
						￥{{data.bd_money}}
					</template>
				</c-table-column>
				
				<c-table-column
					label="保费金额"
					width="120"
				>
					<template v-slot="{data}">
						￥{{data.bf_money}}
					</template>
				</c-table-column>
				
				<c-table-column
					label="失效时间"
					name="bx_bd_end_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					label="申请时间"
					name="create_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					label="被保险人"
					name="bbxr_name"
					width="300"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="150"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button @click="navigateTo('/insurance_detail', {id: data.id})">详情</c-table-button>
						<c-table-button v-if="data.state == 4" @click="claimApplyHandle(data)">申请理赔</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
		
		<c-dialog ref="claimApply" title="申请理赔" width="600" button-name="确认并提交" @resolve="claimApplySubmitHandle">
			<claim-apply ref="claimApplyForm"></claim-apply>
		</c-dialog>
	</page>
</template>

<script>
	import claimApply from './claim_apply.vue'
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		components: {
			claimApply
		},
		
		computed: {
			...mapState(['insuranceStateList']),
			...mapGetters(['insuranceState'])
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data) {
				this.$refs.table.load({
					url: '/project/bx/list',
					data
				});
			},
			
			claimApplyHandle(data) {
				this.$refs.claimApplyForm.init(data.id);
				this.$refs.claimApply.open();
			},
			
			claimApplySubmitHandle(stop, next) {
				stop();
				
				this.$refs.claimApplyForm.submit().then(() => {
					next();
					this.$message({
						text: '提交成功'
					});
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>
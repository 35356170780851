<template>
	<app tabbar>
		<scroll-list-m ref="list">
			<template v-slot="{data}">
				<scroll-item-m>
					<div class="flex-between-center">
						<div class="name">{{data.bx_type_name}}</div>
						<div class="state"><span :style="{'color': insuranceState[data.state].color}">{{insuranceState[data.state].name}}</span></div>
					</div>
					<div class="info">项目名称：{{data.project_name}}</div>
					<div class="info">企业名称：{{data.zbqy_name}}</div>
					<div class="info">被保险人：{{data.bbxr_name}}</div>
					<button-list-m slot="button">
						<c-button color="gray" hollow @click="navigateTo('/insurance_detail', {id: data.id})">详情</c-button>
						<c-button v-if="data.state == 4" color="gray" hollow @click="navigateTo('/claim_apply', {id: data.id})">申请理赔</c-button>
					</button-list-m>
				</scroll-item-m>
			</template>
		</scroll-list-m>
	</app>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		computed: {
			...mapGetters(['insuranceState'])
		},
		
		mounted() {
			this.$refs.list.load({
				url: '/project/bx/list'
			});
		}
	};
</script>

<style lang="stylus" scoped>
</style>